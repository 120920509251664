const prices = [70, 120, 180, 250, 320, 380, 540, 700];

export const onClickJump = (url) => {
  const w = window.open('about:blank');
  w.location.href = url;
};

export const isEmptyOrSpaces = (str) => {
  if (str === undefined || str === null) {
    return true;
  }
  if (typeof str === 'string') {
    return str.match(/^ *$/) !== null;
  }
  return false;
};

export const receipt = async (transactionHash, provider) => {
  const txReceipt = await provider.getTransactionReceipt(transactionHash);
  if (txReceipt && txReceipt.blockNumber) {
    return txReceipt;
  }
  return null;
};

export const getPriceIndex = (current) => {
  const index = current + 1;
  let pos = 0;
  if (index >= 1 && index <= 100) {
    pos = 0;
  } else if (index >= 101 && index <= 200) {
    pos = 1;
  } else if (index >= 201 && index <= 400) {
    pos = 2;
  } else if (index >= 401 && index <= 700) {
    pos = 3;
  } else if (index >= 701 && index <= 1000) {
    pos = 4;
  } else if (index >= 1001 && index <= 10) {
    pos = 5;
  } else if (index >= 1201 && index <= 1300) {
    pos = 6;
  } else if (index >= 1301) {
    pos = 7;
  }
  return pos;
};

export const getCurrentPrice = (current) => prices[getPriceIndex(current)];

export const getNextPrice = (current) => {
  let index = getPriceIndex(current);
  index = index + 1 >= prices.length ? prices.length - 1 : index + 1;
  return prices[index];
};

export const getMulPrice = (current, qty) => {
  const start = current;
  let price = 0;
  for (let index = 0; index < qty; index += 1) {
    price += getCurrentPrice(start + index);
  }
  return price;
};

export const getChainIndex = (id) => {
  if (id === '250') {
    return 6;
  }
  if (id === '1') {
    return 0;
  }
  if (id === '10') {
    return 5;
  }
  if (id === '42161') {
    return 4;
  }
  if (id === '137') {
    return 3;
  }
  if (id === '43114') {
    return 2;
  }
  if (id === '56') {
    return 1;
  }
  return 0;
};

export const getTotalRemaining = (current) =>
  1400 - current > 0 ? 1400 - current : 0;

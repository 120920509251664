import React from 'react';

import './index.scss';
import geometry from '../../assets/img/geometry.png';
import swap1 from '../../assets/img/0swap1.png';
import engine from '../../assets/img/0engine.png';
import enginegif from '../../assets/img/engine.gif';
// import number from "../../assets/img/number.gif"
import nftName from '../../assets/img/nftName.png';
import nftLogo from '../../assets/img/nftLogo.png';
import dex from '../../assets/img/dex.png';
import algo from '../../assets/img/algo.png';
import opera from '../../assets/img/opera.png';
import trans from '../../assets/img/trans.png';
import tree from '../../assets/img/tree.gif';
import ripple from '../../assets/img/ripple.png';
import wave from '../../assets/img/wave.png';
import partner from '../../assets/img/partner.gif';
import partnerList from '../../assets/img/partnerList.png';

import NumberGifComponents from '../../components/numbergif';

function IndexPageComponents() {
  return (
    <div className="homePage">
      <h1 className="pageTitle fontitalic">
        Omnichain Interoperable Payment Protocol Ecosystem.
      </h1>
      <div>
        <img className="geometry" src={geometry} alt="" />
      </div>
      <section className="centerGit">
        <div className="leftCon">
          <img className="oswap" src={swap1} alt="" />
          <p className="infoname fontitalic">· NFT Aggregator</p>
          <p className="infoname fontitalic">· DEX Aggregator</p>
        </div>
        <div className="engineGroup">
          <img className="enginegif" src={enginegif} alt="" />
          <h3 className="Omnichain">Omnichain Engine</h3>
        </div>

        <div className="rightCon">
          <img className="oengine" src={engine} alt="" />
          <p className="fontitalic infoname rightInfo">· Plugins</p>
          <p className="fontitalic infoname rightInfo">· SDK</p>
          <p className="fontitalic infoname rightInfo">· API</p>
        </div>
      </section>

      <h3 className="fontitalic web3Title">
        Web3 won&apos;t be built in a day.
      </h3>

      <div className="numbergif">
        <NumberGifComponents canWidth="550" canHeight="350" />
      </div>
      {/* <img id="numbergif" src={number} alt="" /> */}
      <section className="imgGroup">
        <div className="swapConents">
          <img className="leftimg" src={nftName} alt="" />
          <div className="rightGroup">
            <img className="nftLogo" src={nftLogo} alt="" />
            <div className="fr">
              <div className="fontitalic launchButton addApp">Launch App</div>
              <p className="fontitalic buttonDesc">Coming soon..</p>
            </div>
          </div>
        </div>

        <div className="line" />
        <div className="DexGroup">
          <img className="dexpng" src={dex} alt="" />
          <div className="dexRight">
            <h4 className="fontitalic dexTitle">
              The DEX Aggregator built on LayerZero
            </h4>
            <p className="desc">
              <img className="iconImg" src={algo} alt="" />
              <span className="fontitalic">
                Oswap’s smart routing algorithm.
              </span>
            </p>
            <p className="desc">
              <img className="iconImg" src={opera} alt="" />
              <span className="fontitalic">
                A full-chain wallet with minimal operation.
              </span>
            </p>
            <p className="desc">
              <img className="iconImg" src={trans} alt="" />
              <span className="fontitalic">
                Buy and sell cross-chain assets in one transaction.
              </span>
            </p>
            <div className="fr">
              <div className="fontitalic launchButton adddex">Launch App</div>
              <p className="fontitalic buttonDesc">Coming soon..</p>
            </div>
          </div>
        </div>
      </section>
      <section className="activeTree">
        <h3 className="treeTitle">Hey, OE. What else can you do?</h3>
        <div className="treeGroup">
          <img className="treegif" src={tree} alt="" />
          <p className="fontitalic tcom Gamefi">Gamefi</p>
          <p className="fontitalic tcom NFT">NFT</p>
          <p className="fontitalic tcom DEX">DEX</p>
          <p className="fontitalic tcom defi">Defi</p>
          <p className="fontitalic tcom crossChain">Cross chain</p>
          <p className="fontitalic tcom wallet">Wallet</p>
        </div>
        <img className="ripple" src={ripple} alt="" />
        <img className="wave" src={wave} alt="" />
      </section>

      <section className="partners">
        <img className="partnerGif" src={partner} alt="" />
        <p className="fontitalic pTitle">Partners</p>
      </section>
      <img className="iconGroup" src={partnerList} alt="" />
      <ul className="infoGroup">
        <li>
          <a
            href="https://medium.com/@Tim4l1f3/how-to-launch-an-ominchain-nft-on-layerzero-protocol-fab717635b3b"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className="fontitalic htitle">
              How to launch an omnichain NFT on Layerzero protocol
            </h5>
            <p className="fontitalic desc">
              This article it is going to walk you through building a prototype
              of omnichain NFT with LayerZero. Think of it as building a project
              for a hackathon or a prototype demo for your startup in a limited
              time.As a result, we create A cross-chain NFT contract using
              LayerZero, and use the default UA configuration.
            </p>
            <p className="fontitalic addmore">Read More..</p>
          </a>
        </li>
        <li className="second">
          <a
            href="https://medium.com/@Tim4l1f3/oswap-the-1st-omnichain-aggregator-built-on-layerzero-115319bf43ff"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className="fontitalic htitle">
              Oswap-The 1st Omnichain Aggregator built on #Layerzero
            </h5>
            <p className="fontitalic desc">
              Why choose to build an omnichain Aggregator?
              <br />
              The biggest trend. Multi-chain integration is a definite megatrend
              that is currently under construction. In the next stage, with a
              growing number of multi-chain projects built, omnichain swap will
              be the most basic requirement.
            </p>
            <p className="fontitalic addmore">Read More..</p>
          </a>
        </li>
        <li>
          <a
            href="https://medium.com/@Tim4l1f3/layerzero-tutorial-for-beginners-d3fe9326e8b7"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className="fontitalic htitle">
              LayerZero Tutorial for Beginners
            </h5>
            <p className="fontitalic desc">
              At the first, let&apos;s get an overview of LayerZero.LayerZero is
              an Omnichain Interoperability Protocol designed for lightweight
              message passing across chains. LayerZero provides authentic and
              guaranteed message delivery with configurable trustlessness. The
              protocol is implemented as a set of gas-efficient, non-upgradable
              smart contracts.{' '}
            </p>
            <p className="fontitalic addmore">Read More..</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
export default IndexPageComponents;

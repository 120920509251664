import React from 'react';
import PropTypes from 'prop-types';

let canvsTimers = null;
function getCanvans(node, pwidth, pheight) {
  const c = node;
  if (!c) {
    clearInterval(canvsTimers);
    return;
  }
  const ctx = c.getContext('2d');
  c.height = pheight;
  c.width = pwidth;
  let txts = '01';
  txts = txts.split('');
  const fontSize = 12;
  const columns = pwidth / fontSize;
  const drops = [];
  for (let x = 0; x < columns; x += 1) {
    drops[x] = 1;
  }
  function draw() {
    ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
    ctx.fillRect(0, 0, pwidth, pheight);

    ctx.fillStyle = '#0F0';
    ctx.font = `${fontSize}px arial`;
    for (let i = 0; i < drops.length; i += 1) {
      const text = txts[Math.floor(Math.random() * txts.length)];
      ctx.fillText(text, i * fontSize, drops[i] * fontSize);
      if (drops[i] * fontSize > pheight || Math.random() > 0.95) drops[i] = 0;
      if (i < 5 && Math.random() > 0.45) drops[i] = 0;
      if (i < 10 && i >= 5 && Math.random() > 0.85) drops[i] = 0;
      if (i < 36 && i >= 10 && Math.random() > 0.85) drops[i] = 0;
      if (i > 35 && Math.random() > 0.85) drops[i] = 0;
      if (i > 40 && Math.random() > 0.55) drops[i] = 0;
      drops[i] += 1;
    }
  }
  canvsTimers = setInterval(draw, 33);
}
function NumberGifComponents(props) {
  const { canHeight } = props;
  const { canWidth } = props;
  return (
    <canvas
      ref={(node) => {
        getCanvans(node, canWidth, canHeight);
      }}
      id="changegif"
    />
  );
}

NumberGifComponents.propTypes = {
  canHeight: PropTypes.string.isRequired,
  canWidth: PropTypes.string.isRequired,
};

export default NumberGifComponents;

import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { ToastContainer } from 'react-toastify';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider> */}
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ fontSize: '14px', height: '80px' }}
    />
    <App />
    {/* </Provider> */}

  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';
import { NavLink } from 'react-router-dom';
import './HeaderCom.scss';
import homeLogo from '../assets/img/homeLogo.png';

// const selectedStyle = {
//   color: "#17B449"
// }
function HeaderComComponents() {
  return (
    <div className="headerCom">
      <div className="leftContent">
        <img className="logo" src={homeLogo} alt="" />
        <h2 className="title fontitalic">dLab</h2>
      </div>
      <ul className="rightContent fontitalic">
        <li>
          <NavLink
            to="/"
            data-index={1}
            className={(val) => (val.isActive ? 'active' : 'normal')}
          >
            Home
          </NavLink>
        </li>
        <li>
          <a
            className="linkInfo"
            href="https://doc.dlab.zone/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Docs
          </a>
        </li>
        <li>
          <a
            className="linkInfo"
            href="https://oswap.exchange/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Oswap
          </a>
        </li>
        <li>
          <a
            className="linkInfo"
            href="https://medium.com/@Tim4l1f3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium
          </a>
        </li>
        <li>
          <a
            className="linkInfo"
            href="https://github.com/The-dLab/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
      </ul>
    </div>
  );
}
export default HeaderComComponents;

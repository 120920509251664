import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderComComponents from './components/HeaderCom';
import IndexPageComponents from './pages/index/index';
import FooterComComponents from "./components/footerCom"
import Boxer from "./pages/boxer/main";
import './App.scss';

function App() {
  const theme = createTheme({
    typography: {
      htmlFontSize: 100,
    },
    palette: {
      primary: {
        main: '#17B449',
        // main: '#1BA27A',
        contrastText: '#fff',
      },
      grey: {
        main: '#999999',
        contrastText: '#fff',
      },
      white: {
        main: '#FFF',
        contrastText: '#fff',
      },
    },
  });
  const resizeListener = () => {
    const designSize = 1920;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 100) / designSize;
    html.style.fontSize = `${htmlRem}px`;
    theme.typography.htmlFontSize = htmlRem;
  };
  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <HeaderComComponents />
          <div className="mainContent">
            <Routes>
              {/* Homepage */}
              <Route exact path="/" element={<IndexPageComponents />} />

              <Route exact path="/boxer" element={<Boxer />} />
            </Routes>
            <div className="footer">
              <FooterComComponents />
            </div>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React from 'react';
import './footerCom.scss';
import footerLogo from '../assets/img/footerLogo.png';
import telegramx from '../assets/img/telegram@2x.png';
import twitterx from '../assets/img/twitter@2x.png';
import mediumx from '../assets/img/medium@2x.png';
import footerRImg from '../assets/img/footerRImg.png';

function FooterComComponents() {
  return (
    <div className="footerCom">
      <div className="footerLeft">
        <img className="footerLogo" src={footerLogo} alt="" />
        <p className="fontitalic dLab">© 2022 dLab</p>
      </div>
      <div className="footRight">
        {/* <a href="https://twitter.com/labfordup">
      <img src="../assets/img/discord@2x.png" alt="" />
    </a>  */}
        <a target="_blank" rel="noreferrer" href="https://t.me/dupforX">
          <img src={telegramx} alt="" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/labfordup"
        >
          <img src={twitterx} alt="" />
        </a>
        <a
          className="lastimg"
          rel="noreferrer"
          target="_blank"
          href="https://medium.com/@Tim4l1f3"
        >
          <img src={mediumx} alt="" />
        </a>
      </div>
      <img className="footerRImg" src={footerRImg} alt="" />
    </div>
  );
}
export default FooterComComponents;

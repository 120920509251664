import React, { useState, Children } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import chainIdData from '../utils/chainIdData.json';
import chainData from '../utils/chainData.json';
import logo1 from '../assets/network/ethereum.png';
import logo2 from '../assets/network/binance.png';
import logo3 from '../assets/network/avalanche.png';
import logo4 from '../assets/network/polygon.png';
import logo5 from '../assets/network/arbitrum.png';
import logo6 from '../assets/network/optimism.png';
import logo7 from '../assets/network/fantom.png';

const chainIdList = chainIdData.mainnet;
const chainlist = chainData.mainnet;
const logolist = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];

function TokenItem(props) {
  const { onClickCross, currentIndex, tokenId } = props;
  const [chainIndex, setChainIndex] = useState(currentIndex === 0 ? 1 : 0);
  const [chainId, setChainId] = useState(0);
  const handleChainIndexChange = async (event) => {
    setChainIndex(event.target.value * 1);
    setChainId(chainIdList[event.target.value * 1]);
  };
  const [feeValue, setFeeValue] = useState(2);
  const handleChangeFeeValue = (event) => {
    setFeeValue(event.target.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '0.2rem',
      }}
    >
      <div
        style={{
          width: '50%',
          fontSize: '0.16rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>Boxer </span>
        <span># {tokenId}</span>
      </div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Select
          variant="outlined"
          sx={{ width: '1.5rem', height: '0.4rem', mr: '0.15rem' }}
          value={chainIndex}
          onChange={handleChainIndexChange}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {Children.toArray(
            chainlist.map((item, index) => (
              <MenuItem value={index} disabled={currentIndex === index}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.18rem',
                  }}
                >
                  <Avatar
                    sx={{ width: '0.23rem', height: '0.23rem' }}
                    src={logolist[index]}
                    alt=""
                  />
                  <span style={{ marginLeft: '0.1rem' }}>{item.chainName}</span>
                </div>
              </MenuItem>
            ))
          )}
        </Select>
        <TextField
          label="fee"
          sx={{ width: '0.8rem', fontSize: '0.14rem' }}
          variant="outlined"
          size="small"
          type="number"
          onChange={handleChangeFeeValue}
          value={feeValue}
        />
        <Button
          variant="contained"
          sx={{
            ml: '0.2rem',
            width: '0.8rem',
            height: '0.3rem',
            fontSize: '0.12rem',
          }}
          onClick={() => onClickCross(chainId, tokenId, feeValue)}
        >
          Cross
        </Button>
      </Box>
    </div>
  );
}

TokenItem.propTypes = {
  onClickCross: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default TokenItem;
